import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "membership-dues"
    }}>{`Membership Dues`}</h1>
    <p>{`If you’re a new to our school, you’re welcome to visit for a free demo class. After that, our fees are:`}</p>
    <h2 {...{
      "id": "group-classes"
    }}>{`Group Classes`}</h2>
    <p>{`See `}<Link to="/schedule" mdxType="Link">{`our schedule`}</Link>{` for available group classes.`}</p>
    <ul>
      <li parentName="ul">{`Single Class: $25`}</li>
      <li parentName="ul">{`Monthly Membership: $85`}</li>
      <li parentName="ul">{`Yearly Membership: $850`}</li>
    </ul>
    <h2 {...{
      "id": "private-lessons"
    }}>{`Private Lessons`}</h2>
    <p>{`A private lesson is 45 minutes long. Please contact us to schedule a time.`}</p>
    <ul>
      <li parentName="ul">{`One Student: $30`}</li>
      <li parentName="ul">{`Two or more Students semi-private: $50`}</li>
    </ul>
    <h2 {...{
      "id": "on-site-lessons"
    }}>{`On-Site Lessons`}</h2>
    <p>{`Our instructors also teach Tai Chi outside of our school - at senior centers, libraries, and offices. If you’re interested in hiring someone to come teach at your location, please Contact Us.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      